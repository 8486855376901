import { OscClient } from "@common/oscClient";

(async () => {
    const osc = new OscClient("localhost", 50000);

    let i = 1;
    setInterval(() => {
        osc.send("/test", i++);
    }, 1000);

    osc.on(`*`, (message) => {
        console.debug(`OSC on(${message.address}${0 < message.args.length ? `, ${message.args}` : ``})`);
    });
})();
