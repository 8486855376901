import OSC from "osc-js";

export interface IOscNode {
    on(address: string, callback: (message: OSC.Message) => void): void;
    send(address: string, ...args: any[]): void;
}

export class OscNode implements IOscNode {
    constructor(osc: OSC) {
        this.osc = osc;

        setInterval(() => {
            if (this.osc.status() === OSC.STATUS.IS_CLOSED) {
                this.osc.open();
            }
            this.logStatus();
        }, 1000);

        this.osc.on(`*`, (message: OSC.Message) => {
            console.debug(`OSC received (${message.address}${0 < message.args.length ? `, ${message.args}` : ``})`);
        });

        this.osc.open();
    }

    on(address: string, callback: (message: OSC.Message) => void) {
        this.osc.on(address, callback);
    }

    send(address: string, ...args: any[]) {
        if (this.osc.status() !== OSC.STATUS.IS_OPEN) {
            return;
        }
        this.osc.send(new OSC.Message(address, ...args));
    }

    private logStatus() {
        const status = this.osc.status();

        if (status !== this.previousOscStatus) {
            if (OSC.STATUS.IS_CLOSED === this.osc.status() || OSC.STATUS.IS_CLOSED === this.osc.status()) {
                console.debug(`OSC closed`);
            }
            else if (OSC.STATUS.IS_CONNECTING === this.osc.status()) {
                console.debug(`OSC connecting ...`);
            }
            else if (OSC.STATUS.IS_OPEN === this.osc.status()) {
                console.debug(`OSC open`);
            }
            else {
                console.warn(`Unknown OSC status`);
            }

            this.previousOscStatus = status;
        }
    }

    private osc: OSC;
    private previousOscStatus: number = OSC.STATUS.IS_NOT_INITIALIZED;
}
